import React from "react";
import { Card, CardContent, Typography, Box, IconButton } from "@mui/material";
import { Edit, Delete } from "@mui/icons-material";

const articles = [
  {
    title: "Lorem Ipsum",
    description: "This is a sample description for the article.",
    createdBy: "John Doe",
    date: "March 19, 2025",
    image: "https://umbrellacreative.com.au/wp-content/uploads/2020/01/hide-the-pain-harold-why-you-should-not-use-stock-photos.jpg", // Replace with actual image URL
    isDraft: true,
  },
  {
    title: "Dolor Sit Amet",
    description: "Another sample article with details.",
    createdBy: "Jane Smith",
    date: "March 18, 2025",
    image: "https://umbrellacreative.com.au/wp-content/uploads/2020/01/hide-the-pain-harold-why-you-should-not-use-stock-photos.jpg", // Replace with actual image URL
    isDraft: false,
  },
];

export default function TertiaryArticles() {
  return (
    <Box className="tertiary-articles">
      {articles.map((article, index) => (
        <Card key={index} className="tertiary-article-card">
          {/* Left Section: Image */}
          <Box className="tertiary-article-image" style={{ backgroundImage: `url(${article.image})` }} />

          {/* Right Section: Content */}
          <CardContent className="tertiary-article-content">
            <Box className="tertiary-article-header">
              <Typography variant="h6" className="tertiary-article-title">
                {article.title}
              </Typography>
              {article.isDraft && <Typography className="draft-label shadow">DRAFT</Typography>}
            </Box>

            <Typography variant="body2" className="tertiary-article-description">
              {article.description}
            </Typography>

            <Typography variant="caption" className="tertiary-article-meta">
              {`Created by ${article.createdBy} - ${article.date}`}
            </Typography>

            {/* Edit and Delete Icons */}
            <Box className="tertiary-article-actions">
              <IconButton size="small" className='icon-button'>
                <Edit />
              </IconButton>
              <IconButton size="small" className='icon-button'>
                <Delete />
              </IconButton>
            </Box>
          </CardContent>
        </Card>
      ))}
    </Box>
  );
}
