const Event = {
  ALERT: 'ALERT',
  AUTH_AUTHORIZE: 'AUTH_AUTHORIZE',
  AUTH_SESSION_EXTEND: 'AUTH_SESSION_EXTEND',
  TIMER_STOPPED: 'TIMER_STOPPED',
  TIMER_STARTED: 'TIMER_STARTED',
  ON_NAVIGATE: 'ON_NAVIGATE',
  ON_INITIALIZED: 'ON_INITIALIZED',
  ON_PAGE_LOADED: 'ON_PAGE_LOADED',
  ON_PAGE_LOADING: 'ON_PAGE_LOADING',
  ON_MENU_CONTEXT_CHANGE: 'ON_MENU_CONTEXT_CHANGE',
  SHOW_PROGRESS_MODAL: 'SHOW_PROGRESS_MODAL',
  HIDE_PROGRESS_MODAL: 'HIDE_PROGRESS_MODAL',
  PROGRESS_MODAL_PROGRESS: 'PROGRESS_MODAL_PROGRESS',
}

export default Event