import { useContext, useMemo, useState, useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import Menu from './Menu'
import { Typography, Skeleton } from '@mui/material'
import { Apartment, TaskAlt, Approval, AssignmentInd, Dangerous, FactCheck, Factory, Healing, People, Science, MonitorHeart, HealthAndSafety, OpenInNewRounded, Troubleshoot, Contacts, Hub } from '@mui/icons-material'
import MenuItem from './MenuItem'
import MenuDivider from './MenuDivider'
import { ROLES } from '../auth/role'
import RoleContext from '../auth/RoleContext'
import UserContext from '../auth/UserContext'
import HelpDialog from '../help/HelpDialog'
import AssessmentGuidelines from '../help/AssessmentGuidelines'
import RiskMatrix from '../help/RiskMatrix'
import { FeatureFlags } from '../config/config'
import { loadOwnership } from '../ownership/data'
import { loadManufacturer } from '../manufacturer/data'
import EventBus from '../utils/EventBus' 
import EventNames from '../utils/EventNames'

export default function SidebarMenu(props = {}) {
  window.scrollTo(0, 0)
  const location = useLocation()
  const roleDetails = useContext(RoleContext)
  const userDetails = useContext(UserContext)

  const [ownershipUuid, setOwnershipUuid] = useState(null)
  const [ownership, setOwnership] = useState({})
  const [manufacturerUuid, setManufacturerUuid] = useState(null)
  const [manufacturer, setManufacturer] = useState({})
  const [manufacturerProfile, setManufacturerProfile] = useState({})
  const [expanded, setExpanded] = useState('')

  const isNorthstarBottler = () => {
    if (roleDetails.hasRole([ROLES.BOTTLER, ROLES.BOTTLER_CORP])) {
      return userDetails.userProfile.manufacturers?.some((m) => {
        return m.isNorthstar
      })
    }
    return false
  }

  useEffect(() => {
    const listener = EventBus.addListener(EventNames.ON_MENU_CONTEXT_CHANGE, (context) => {
      if(context.manufacturerUuid) {
        setManufacturerUuid(context.manufacturerUuid)
        setManufacturer(context.manufacturer)
        if(context.manufacturerProfile) {
          setManufacturerProfile(context.manufacturerProfile)
        }
      }
      if(context.ownershipUuid) {
        setOwnershipUuid(context.ownershipUuid)
        setOwnership(context.ownership)
      }
    })
    return () => {
      if(listener) {
        EventBus.removeListener(EventNames.ON_MENU_CONTEXT_CHANGE, listener)
      }
    }
  }, [
    setManufacturerUuid,
    setManufacturer,
    setOwnershipUuid,
    setOwnership,
  ])

  const animationSpeed = 200

  useEffect(() => {
    const ownershipExpandedRe = new RegExp('/ownership/edit/.*')
    const ownershipExpanded = ownershipExpandedRe.test(location.pathname)
    const manufacturerExpandedRe = new RegExp('/manufacturer/edit/.*')
    const manufacturerExpanded = manufacturerExpandedRe.test(location.pathname)
    const safetyHubExpanded = /\/safety-community-hub\/.*/.test(location.pathname)

    if(ownershipExpanded) {
      setExpanded('ownership')
    } else if(manufacturerExpanded) {
      setExpanded('manufacturer')
    } else if(safetyHubExpanded) {
      setExpanded('safety-hub')
    } else {
      setExpanded('')
      setManufacturer({})
      setOwnership({})
    }
  }, [location.pathname])

  return (
    <Menu>
      {roleDetails.hasAnyRole() &&
        <>
          {!roleDetails.hasRole([ROLES.IM_VIEWER, ROLES.IM_MANAGER]) && <>
            <MenuItem activePaths={['/my-assessments']} href="/my-assessments" title="My Assessments" icon={<AssignmentInd />} />
            <MenuItem activePaths={['/corrective-action-plan/my-list']} href="/corrective-action-plan/my-list" title="My Corrective Actions" icon={<Approval />} />
          </>}

          {FeatureFlags.facman && !roleDetails.hasRole([ROLES.IM_VIEWER]) && <>
            <MenuItem id='ownership-menu' animationSpeed={animationSpeed} expanded={expanded === 'ownership'} activePaths={['ownership/list', '/ownership/edit']} title="Ownerships" href="/ownership/list" icon={<Apartment />}>
              <MenuItem wrap title={
                ownership?.ownershipName
                  ? <Typography fontWeight='800' variant='body2'>{ownership?.ownershipName}</Typography>
                  : <Skeleton sx={{ height: '18px', width: '200px' }} />
              } />
              <MenuItem activePaths={['/ownership/edit/[^/]*/details']} href={`/ownership/edit/${ownershipUuid}/details`} title={<Typography fontWeight='400' variant='body2'>Ownership Details</Typography>} />
              <MenuItem activePaths={['/ownership/edit/[^/]*/contacts']} href={`/ownership/edit/${ownershipUuid}/contacts`} title={<Typography fontWeight='400' variant='body2'>Contacts</Typography>} />
            </MenuItem>
          </>}

          {FeatureFlags.facman && manufacturer && !roleDetails.hasRole([ROLES.IM_VIEWER]) && <>
            <MenuItem id='manufacturerProfile-menu' animationSpeed={animationSpeed} expanded={expanded === 'manufacturer'} activePaths={['manufacturer/list', '/manufacturer/edit']} title="Facilities" href="/manufacturer/list" icon={<Factory />}>
              <MenuItem wrap title={
                manufacturer?.manufacturerName
                  ? <Typography fontWeight='800' variant='body2'>{manufacturer?.manufacturerName}&nbsp;</Typography>
                  : <Skeleton sx={{ width: '200px' }} />
              } />
              <MenuItem activePaths={['/manufacturer/edit/[^/]*/details']} href={`/manufacturer/edit/${manufacturerUuid}/details`} title={<Typography fontWeight='400' variant='body2'>Facility Details</Typography>} />
              <MenuItem activePaths={['/manufacturer/edit/[^/]*/contacts']} href={`/manufacturer/edit/${manufacturerUuid}/contacts`} title={<Typography fontWeight='400' variant='body2'>Contacts</Typography>} />
              {['Producing Plant', 'Co-packer'].includes(manufacturer?.type) && manufacturerProfile?.id &&
                <>
                  <MenuItem activePaths={['/manufacturer/edit/[^/]*/assessment-contacts']} href={`/manufacturer/edit/${manufacturerUuid}/assessment-contacts`} title={<Typography fontWeight='400' variant='body2'>Assessment Contacts</Typography>} />
                  <MenuItem activePaths={['/manufacturer/edit/[^/]*/profile']} href={`/manufacturer/edit/${manufacturerUuid}/profile`} title={<Typography fontWeight='400' variant='body2'>Manufacturer Profile</Typography>} />
                  <MenuItem activePaths={['/manufacturer/edit/[^/]*/products-processes']} href={`/manufacturer/edit/${manufacturerUuid}/products-processes`} title={<Typography fontWeight='400' variant='body2'>Products & Processes</Typography>} />
                  <MenuItem activePaths={['/manufacturer/edit/[^/]*/raw-materials']} href={`/manufacturer/edit/${manufacturerUuid}/raw-materials`} title={<Typography fontWeight='400' variant='body2'>Raw Materials</Typography>} />
                  <MenuItem activePaths={['/manufacturer/edit/[^/]*/comanufactured-product']} href={`/manufacturer/edit/${manufacturerUuid}/co-manufactured`} title={<Typography fontWeight='400' variant='body2'>Co-Manufactured Products</Typography>} />
                  <MenuItem activePaths={['/manufacturer/edit/[^/]*/business-continuity']} href={`/manufacturer/edit/${manufacturerUuid}/business-continuity`} title={<Typography fontWeight='400' variant='body2'>Business Continuity</Typography>} />
                </>
              }
            </MenuItem>
          </>}

          {/* {!FeatureFlags.facman &&  */}
          {!roleDetails.hasRole([ROLES.IM_VIEWER]) && <MenuItem activePaths={['/manufacturer/profile']} href="/manufacturer/profile/list" title="Manufacturer Profiles" icon={<Factory />} />}
          {/* } */}
        </>
      }

      {roleDetails.hasRole([ROLES.QSE_MGR, ROLES.MFI_ADMIN, ROLES.ADMIN]) &&
        <MenuItem activePaths={['/facility-authorizations']} href="/facility-authorizations/list" title="Facility Authorizations" icon={<TaskAlt />} />
      }

      {roleDetails.hasAnyRole() && !roleDetails.hasRole([ROLES.IM_VIEWER, ROLES.IM_MANAGER]) &&
        <MenuItem activePaths={['/process-capability-submissions']} href="/process-capability-submissions/list" title="Process Capability Data" icon={<Science />} />
      }

      {roleDetails.hasRole([ROLES.QSE_MGR, ROLES.MFI_ADMIN, ROLES.ADMIN]) && <MenuItem activePaths={['/manufacturer/production-volumes']} href="/manufacturer/production-volumes" title="Production Volume Reporting" icon={<Factory />} />}

      {isNorthstarBottler() &&
        <MenuItem activePaths={['/manufacturer/production-volumes']} href="/manufacturer/production-volumes" title="Production Volume Reporting" icon={<Factory />}
        />}

      {roleDetails.hasRole([ROLES.BOTTLER, ROLES.BOTTLER_CORP]) &&
        <MenuItem activePaths={['/incident-management']} href="/incident-management/list" title="Incident Management" icon={<HealthAndSafety />}
        />}

      {roleDetails.hasRole([ROLES.QSE_MGR, ROLES.MFI_ADMIN, ROLES.ADMIN]) &&
        <>
          {<MenuItem activePaths={['/complaint-trends']} href="/complaint-trends/list" title="Complaint Trends" icon={<Troubleshoot />} />}
        </>
      }

      {roleDetails.hasRole([ROLES.QSE_MGR, ROLES.MFI_ADMIN, ROLES.ADMIN, ROLES.IM_MANAGER, ROLES.IM_VIEWER]) &&
        <MenuItem activePaths={['/incident-management']} href="/incident-management/list" title="IM Logs" icon={<HealthAndSafety />} />
      }

      {!roleDetails.hasRole([ROLES.IM_MANAGER, ROLES.IM_VIEWER]) && <MenuDivider />}

      {roleDetails.hasRole([ROLES.QSE_MGR, ROLES.MFI_ADMIN, ROLES.ADMIN]) &&
        <>
          {FeatureFlags.mfi &&
            <MenuItem activePaths={['/fitness-indexes']} href="/fitness-indexes" title="Fitness Indexes" icon={<MonitorHeart />} />
          }
          <MenuItem activePaths={['/assessments']} href="/assessments" title="Facility Assessments" icon={<FactCheck />} />
          <MenuItem activePaths={['/corrective-action-plan/(?!my-list)']} href="/corrective-action-plan/list" title="Facility Corrective Action" icon={<Healing />} />
        </>
      }
      {roleDetails.hasAnyRole() && !roleDetails.hasRole([ROLES.IM_MANAGER, ROLES.IM_VIEWER]) &&
        <>
          <li>
            <HelpDialog linkText='Assessment Guidelines' startIcon={<OpenInNewRounded />}>
              <AssessmentGuidelines />
            </HelpDialog>
          </li>
          <li>
            <HelpDialog linkText='Risk Matrix' startIcon={<OpenInNewRounded />} supportZoom={true}>
              <RiskMatrix />
            </HelpDialog>
          </li>
        </>
      }
      {FeatureFlags.safetyHub && roleDetails.hasRole([
        ROLES.ADMIN, ROLES.BOTTLER, ROLES.BOTTLER_CORP, ROLES.MFI_ADMIN, ROLES.QSE_MGR, ROLES.QSE_SAFETY_MANAGER
      ]) &&
        <MenuItem id='safety-hub-menu' href='/safety-community-hub/news' animationSpeed={animationSpeed} expanded={expanded === 'safety-hub'} activePaths={[/\/safety-community-hub\/*./]} title="Safety Community Hub" icon={<Hub />}>
          <MenuItem activePaths={['/safety-community-hub/news']} href={`/safety-community-hub/news`} title={<Typography fontWeight='400' variant='body2'>Community News</Typography>} />
          <MenuItem activePaths={['/safety-community-hub/ou-corner']} href={`/safety-community-hub/ou-corner`} title={<Typography fontWeight='400' variant='body2'>Safety OU Corner</Typography>} />
          <MenuItem activePaths={['/safety-community-hub/resource-library']} href={`/safety-community-hub/resource-library`} title={<Typography fontWeight='400' variant='body2'>Resource Library</Typography>} />
          <MenuItem activePaths={['/safety-community-hub/forum']} href={`/safety-community-hub/forum`} title={<Typography fontWeight='400' variant='body2'>Community Forum</Typography>} />
        </MenuItem>
      }
      {roleDetails.hasRole([ROLES.QSE_MGR, ROLES.MFI_ADMIN, ROLES.ADMIN]) &&
        <>
          <MenuDivider />
          <MenuItem activePaths={['/users']} href="/users/list/" title="User Management" icon={<People />} />
        </>
      }
      {FeatureFlags.contactMgmtAdmin && roleDetails.hasRole([ROLES.QSE_MGR, ROLES.MFI_ADMIN, ROLES.ADMIN]) &&
        <MenuItem activePaths={['/contacts']} href="/contacts/" title="Contacts & Assignments" icon={<Contacts />} />
      }
      {FeatureFlags.contactMgmtBottler && roleDetails.hasRole([ROLES.BOTTLER, ROLES.BOTTLER_CORP]) &&
        <MenuItem activePaths={['/contacts']} href="/contacts/" title="Contacts & Assignments" icon={<Contacts />} />
      }
      {roleDetails.hasRole([ROLES.ADMIN]) &&
        <MenuItem activePaths={['/app/admin']} href="/app/admin" title="Application Admin" icon={<Dangerous />}
        />}
    </Menu>
  )
}
