export const domain = 'https://mfi-api.test.tis.coke.com'
// export const azureClientId = '64236313-9cda-4024-b94b-5c41acf1b9e8'
export const azureClientId = '1f77d5be-7613-4b27-98f3-d49923d8fa46'
export const azureTenantId = 'eb36d020-2c35-44de-bc7a-554cc979b26d'
export const apiKey = 'SyvAFAUua29HhpuJCtRRt7xwYNAIAp9o4hApYNLB'
export const stage = 'test'
export const featureFlags = {
  '764709': true,
  '792789': true,
  '1896027': true,
  authorization: true,
  cpkBottledWater: true,
  mfi: true,
  facman: true,
  facAuthQseUpdates: true,
  contactMgmtAdmin: true,
  contactMgmtBottler: false,
  exposeAuthorizationLetter: false,
  safetyHub: false,
}