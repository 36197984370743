import React from "react";
import { Card, CardContent, Typography, Box } from "@mui/material";

const articles = [
  {
    title: "Magna Aliqua",
    image: "https://images.ctfassets.net/hrltx12pl8hq/3Mz6t2p2yHYqZcIM0ic9E2/3b7037fe8871187415500fb9202608f7/Man-Stock-Photos.jpg", // Replace with actual image URL
  },
  {
    title: "Ullamcorper Justo",
    image: "https://images.ctfassets.net/hrltx12pl8hq/3Mz6t2p2yHYqZcIM0ic9E2/3b7037fe8871187415500fb9202608f7/Man-Stock-Photos.jpg", // Replace with actual image URL
  },
  {
    title: "Habitasse Condimentum",
    image: "https://images.ctfassets.net/hrltx12pl8hq/3Mz6t2p2yHYqZcIM0ic9E2/3b7037fe8871187415500fb9202608f7/Man-Stock-Photos.jpg", // Replace with actual image URL
  },
];

export default function SecondaryArticles() {
  return (
    <Box className="secondary-articles">
      {articles.map((article, index) => (
        <Card key={index} className="secondary-article-card" style={{ backgroundImage: `url(${article.image})` }}>
          <Box className="image-overlay" />
          <CardContent className="secondary-article-content">
            <Typography variant="body1" className="secondary-article-title">
              {article.title}
            </Typography>
          </CardContent>
        </Card>
      ))}
    </Box>
  );
}
