import React, { useState, useEffect } from "react";
import { Card, CardMedia, IconButton, Typography, Box } from "@mui/material";
import { ChevronLeft, ChevronRight, Edit, Delete, Add } from "@mui/icons-material";
import sharepointResponse from './sharepointResponse.js'
import axios from 'axios'

// const articles = [
//   {
//     title: "Lorem Ipsum!!",
//     author: "John Doe",
//     date: "March 19, 2025",
//     image: "https://media.istockphoto.com/id/1346125184/photo/group-of-successful-multiethnic-business-team.jpg?s=612x612&w=0&k=20&c=5FHgRQZSZed536rHji6w8o5Hco9JVMRe8bpgTa69hE8=", // Replace with actual image URL
//   },
//   {
//     title: "Another Article",
//     author: "Jane Smith",
//     date: "March 18, 2025",
//     image: "https://media.istockphoto.com/id/1346125184/photo/group-of-successful-multiethnic-business-team.jpg?s=612x612&w=0&k=20&c=5FHgRQZSZed536rHji6w8o5Hco9JVMRe8bpgTa69hE8=", // Replace with actual image URL
//   },
// ];


export default function FeaturedArticle() {
  const [index, setIndex] = useState(0);
  const [articles, setArticles] = useState([])

  const prevArticle = () => {
    setIndex((prev) => (prev === 0 ? articles.length - 1 : prev - 1));
  };

  const nextArticle = () => {
    setIndex((prev) => (prev === articles.length - 1 ? 0 : prev + 1));
  };

  useEffect(() => {
    (async () => {
      // const url = `https://graph.microsoft.com/v1.0/sites/dba6472d-10cd-4296-8f31-83add015c944/pages/15eeb4d0-f179-4423-99bc-aced572c1239/microsoft.graph.sitePage?$expand=canvasLayout`
      const options = {
        headers: {
          Authorization: `Bearer ${window.sessionStorage.mockGraphToken}`
        }
      }
      const url = `https://graph.microsoft.com/beta/sites/dba6472d-10cd-4296-8f31-83add015c944/pages`
      const { data: pages } = await axios.get(url, options)
      const _articles = []
      for (const newsPost of pages.value.filter(r => r.promotionKind === 'newsPost' || r['@odata.type'] === '#microsoft.graph.newsLinkPage')) {
        if (newsPost['@odata.type'] === '#microsoft.graph.newsLinkPage') {
          _articles.push({
            title: newsPost.title,
            author: newsPost.createdBy.user.displayName,
            date: new Date(newsPost.createdDateTime).toLocaleDateString(),
            image: newsPost.bannerImageWebUrl
          })
        } else {
          const detailedUrl = `https://graph.microsoft.com/beta/sites/dba6472d-10cd-4296-8f31-83add015c944/pages/${newsPost.id}/microsoft.graph.sitePage?$expand=canvasLayout`
          const { data: article } = await axios.get(detailedUrl, options)
          const image = article.bannerImageWebUrl ?? article.thumbnailWebUrl.indexOf('getpreview.ashx') > -1
            ? `${article.thumbnailWebUrl}&resolution=6`
            : article.thumbnailWebUrl.replace(/large.jpg/, 'xxlarge.jpg')
          _articles.push({
            title: article.title,
            author: article.createdBy.user.displayName,
            date: new Date(article.createdDateTime).toLocaleDateString(),
            image
          })
        }
      }
      setArticles(_articles)
    })()
  }, [])

  return (
    <Card className="featured-article">
      <CardMedia component="img" image={articles[index]?.image} alt="Featured Article" className="featured-image" />

      {/* Black Gradient Overlay */}
      <Box className="gradient-overlay" />

      {/* Article Info - Title, Author, Date */}
      <Box className="article-info">
        <Typography variant="h6" className="article-title">
          {articles[index]?.title}
        </Typography>
        <Typography variant="body2" className="article-subtitle">
          {articles[index]?.author} • {articles[index]?.date}
        </Typography>
      </Box>

      {/* Top Row Controls (Add, Draft Label, Edit, Delete) */}
      <Box className="top-controls">
        <IconButton className="icon-button shadow">
          <Add />
        </IconButton>

        <Typography variant="body2" className="draft-label shadow">
          DRAFT
        </Typography>

        <Box className="edit-delete-icons">
          <IconButton className="icon-button shadow">
            <Edit />
          </IconButton>
          <IconButton className="icon-button shadow">
            <Delete />
          </IconButton>
        </Box>
      </Box>

      <Box className="nav-buttons">
        {/* Navigation Buttons */}
        <IconButton className="nav-button left" onClick={prevArticle}>
          <ChevronLeft sx={{ color: 'white' }} fontSize="large" />
        </IconButton>
        <IconButton className="nav-button right" onClick={nextArticle}>
          <ChevronRight sx={{ color: 'white' }} fontSize="large" />
        </IconButton>
      </Box>

    </Card>
  );
}
