import { default as React, useContext, useEffect, useState, useMemo } from 'react'
import { Paper, Grid } from '@mui/material'
import { Factory } from '@mui/icons-material'
import Page from '../layout/Page'
import { useParams, useLocation, useNavigate, useBlocker } from 'react-router-dom'
import RoleContext from '../auth/RoleContext';
import { ROLES } from '../auth/role';
import EventBus from '../utils/EventBus';
import FeaturedArticle from './FeaturedArticle'
import SecondaryArticles from './SecondaryArticles'
import TertiaryArticles from './TertiaryArticles'
import './CommunityNewsPage.css'

export default function CommunityNewsPage(props = {}) {
  const navigate = useNavigate()
  const location = useLocation()
  const params = useParams()
  const roleDetails = useContext(RoleContext)
  const [isLoading, setIsLoading] = useState(true)
  const [editMode, setEditMode] = useState(true)
  const [confirmDialog, setConfirmDialog] = useState(false)

  const [isValidForSave, setIsValidForSave] = useState(false)



  const breadcrumb = <div><span>Safety Community News</span></div>

  const onSave = async () => {
    EventBus.emit('saveRequested')
    setIsValidForSave(false)
  }

  const onStateValidation = (inst, valid) => {
    setIsValidForSave(valid)
  }

  const startLoading = () => {
    setIsLoading(true)
  }

  const stopLoading = () => {
    setIsLoading(false)
  }

  const onEdit = () => {
    setEditMode(true)
  }

  const initializePage = async () => {
    startLoading()
    // 
    stopLoading()
  }

  useEffect(() => {
    initializePage()
  }, [])

  return (
    <Page {...props} breadcrumb={breadcrumb} isLoading={isLoading}
    // commandBar={
    //   <FormViewCommandBar
    //     onSave={onSave}
    //     onEdit={onEdit}
    //     onCancel={handleClose}
    //     onClose={handleClose}
    //     enableSave={isValidForSave}
    //     showClose={!editMode}
    //     showCancel={editMode}
    //     showEdit={false}
    //     showSave={editMode}
    //   />
    // }
    >

      <Paper
        component="form"
        sx={{ backgroundColor: 'transparent', p: '0 0 3em 0', border: 'none', boxShadow: 'none', display: 'flex', flexDirection: 'column', flexWrap: 'wrap', margin: '1em auto' }}
      >
        <Grid container gap={1}>
          <Grid item xs={7.9}>
            <FeaturedArticle />
          </Grid>
          <Grid item xs={4}>
            <SecondaryArticles />
          </Grid>
          <Grid item xs={12}>
            <TertiaryArticles />
          </Grid>
        </Grid>

      </Paper>
    </Page>
  )
}